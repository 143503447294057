import { KEY_PERSISTENT_LOCAL_STORAGE } from './index'
import moment from 'moment'
import i18next from 'i18next'
import Either from 'data.either/lib/either'
import backend from '../backend'
import { fetchJsonWithJWT, catchResponse401ResetAuthToken } from '../commons/utils'


/* Application State: Navigation, Modus, ...  */
const appState = {
	state: () => ({
		feedbackMessage: '',
		permanentMessage: '',
		preferFrontCamera: false,
		feedbackVariant: 'warning',
		permanentVariant: 'warning',
		currentScreen: 'dashboard',
		aekName: i18next.t('aek.name.default')
	}),
	getters:{
		version: () => '1.6.0 development Build 2021092001',
		authorizationToken: state => state.authJWT,
		aekName: state => state.aekName,
		preferFrontCamera: state => state.preferFrontCamera,
		getFeedbackVariant: state => state.feedbackVariant,
		getFeedbackMessage: state => state.feedbackMessage,
		getPermanentMessage: state => state.permanentMessage,
		getPermanentVariant: state => state.permanentVariant,
		hotDurationOptions: () => optionsStatusHotMinutes,

		isScreenDashboard:  (state, getters, rootState) => state.currentScreen === 'dashboard',
		isScreenSettings: ( state, getters ) => state.currentScreen === 'settings',
		isScreenSettingsWaitingRoom: ( state, getters ) => state.currentScreen === 'settingsWaitingRoom',
		isScreenImressum: ( state, getters ) => state.currentScreen === 'impressum',
		isScreenPrivacyPolicy: ( state, getters ) => state.currentScreen === 'privacypolicy',
		isScreenPublicKeys: ( state, getters ) => state.currentScreen === 'publickeys',
		isScreenAbout: ( state, getters ) => state.currentScreen === 'about',
    
	},
	mutations: {
		aekName (state, payload) { state.aekName = payload },
		
		currentScreen (state, payload) { state.currentScreen = payload },
		preferFrontCamera (state, payload) { state.preferFrontCamera = payload },
	
		feedbackMessage(state, payload = {}) { 
			const { message = '', variant = 'warning' } = payload
			state.feedbackMessage = message 
			state.feedbackVariant = variant
		},
		permanentMessage(state, payload = {}) { 
			const { message = '', variant = 'warning' } = payload
			state.permanentMessage = message 
			state.permanentVariant = variant
		}
	
	},
	actions: {
		persistServerAuthorisation: (ctx, payload) => { ctx.commit('persistServerAuthorisation', payload?true:false) },
		preferFrontCamera: (ctx, payload) => { ctx.commit('preferFrontCamera', payload?true:false) },
		showDashboard: (ctx, payload) => { ctx.commit('currentScreen', 'dashboard') },
		showSettings: (ctx, payload) => { ctx.commit('currentScreen', 'settings') },
		showPublicKeys: (ctx, payload) => { ctx.commit('currentScreen', 'publickeys') },
		showImpressum: (ctx, payload) => { ctx.commit('currentScreen', 'impressum') },
		showPrivacyPolicy: (ctx, payload) => { ctx.commit('currentScreen', 'privacypolicy') },
		showAbout: (ctx, payload) => { ctx.commit('currentScreen', 'about') },

		setFeedbackMessage: (ctx, payload = {}) => { 
			ctx.commit('feedbackMessage', payload)
			const {ttl = 0} = payload
			if(ttl) setTimeout(() => { ctx.commit('feedbackMessage', {}) }, ttl)
		},
		dimissFeedbackMessage: ctx => { ctx.commit('feedbackMessage', {}) },
		setPermanentMessage: (ctx, payload = {}) => { 
			ctx.commit('permanentMessage', payload)
		},
		test: (ctx) => console.log('appState - action'),
		resetApp: (ctx) => {
			ctx.commit('restoreFactorySettings') 
			window.localStorage.removeItem(KEY_PERSISTENT_LOCAL_STORAGE)
			window.location.reload()
			
		},
		changePassword: async (ctx, payload) => {
			const authToken = ctx.getters.kkJWT
			const resp = await changePasswordOnBackend(authToken, payload)
			catchResponse401ResetAuthToken(ctx, resp)
			if(resp.isRight){
				if(resp.value.affectedRows === 1){
					ctx.dispatch('setFeedbackMessage', {message: i18next.t('feedback.message.change.pwd.ok'), variant:'success', ttl:12200})
				}
			}
			return resp
		}
	
	}
}

export default appState

