import Vue from 'vue'
import Vuex from 'vuex'
import Backend from '../backend'
import { requestJson, requestJsonWithJWT } from '@/commons/utils'
import VuexPersistence from 'vuex-persist'
import _throttle from 'lodash/throttle'
import createLogger from 'vuex/dist/logger'

// modules
import appState from './appState'

import transientState from './transientState'
import videoDevicesState from './videoDevicesState'
import kkState from './kkState'

export const KEY_PERSISTENT_LOCAL_STORAGE = 'org.butler.kk.v1'

const vuexLocal = new VuexPersistence({
	storage: window.localStorage,
	key: KEY_PERSISTENT_LOCAL_STORAGE,
	modules: [ 'appState', 'videoDevicesState' ],
	saveState: _throttle((key, state, storage) => {
		if(state && state.transientState && state.transientState.restoreFactorySettings) return
		//if(!state.appState.persistServerAuthorisation) state.kkState.jwt = ''
		//state.kkState.password = ''
		storage.setItem(key, JSON.stringify(state));
	}, 800)
})

const plugins = [ vuexLocal.plugin ]
const debug = process.env.NODE_ENV !== 'production'
//if(debug)plugins.push(createLogger({}))

Vue.use(Vuex)
const store = new Vuex.Store({
	
	modules:{
		transientState,
		appState, kkState, videoDevicesState
	},
	actions: {
		test: (ctx) => console.log('ROOT - action')
	},
	plugins
})

export default store