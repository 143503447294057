<template>
	<div>
		<h5>{{$t('public.keys.header.text')}}</h5>
		<p class="small text-center">{{$t('public.keys.storage.description')}}</p>
		<p>{{$t('public.keys.configured')}}: {{ countConfiguredPublicKeys}} von {{ countConfigurablePublicKeys }}</p>
		<b-button @click="toPublicKeysScreen()">{{$t('configure')}}</b-button>
	</div>
</template>

<script>
export default {
	computed:{
		countConfiguredPublicKeys() { return this.$store.getters.kkPublicKeys.length },
		countConfigurablePublicKeys() { return this.$store.getters.kkPublicKeyPurposes.length },
	},
	methods:{
		toPublicKeysScreen: function(){
      this.$store.dispatch( 'showPublicKeys' )
		},
	},

}
</script>

<style>

</style>