<template>
	
  <header class="navbar  navbar-light bg-light" v-bind:class="{'fixed-top' : fixedTop}">
    <a class=" navbar-brand" href="#" @click="toHomeScreen()">
      <div class="ml-1  d-inline-block logo">
        <img  src="../resources/storelogo.png" />
      </div>
      <div class="ml-2 d-inline-block text-left align-middle">
        <div class="mb-0 mt-0 h5">{{$t('app.name')}} {{ titlePostfix }}</div>
        <div class="h6 font-weight-light pt-0">{{aekName}}</div>
      </div>
    </a>
    <form class="form-inline my-2 my-lg-0">
      <button onClick="" class="mx-2 navbar-toggler" type="button" data-toggle="dropdown" 
        data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class = "navbar-toggler-icon"></span>
      </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <div class="dropdown-menu dropdown-menu-right" aria-labelledby={anId}>
        <div class="dropdown-item clickableValue"  @click="toHomeScreen()">
          {{$t("dashboard")}}
        </div>
        <div class="dropdown-item clickableValue" @click="toPublicKeysScreen()">
          {{$t("menu.publickeys.screen")}}
        </div>

        <div class="dropdown-divider"></div>
        <div class="dropdown-item clickableValue"  @click="werkeinstellungen()">
          {{$t("factory.settings")}}
          
        </div>

        <div class="dropdown-item clickableValue"  @click="account()">
          {{$t("account")}}
          
        </div>
        <div class="dropdown-item clickableValue"  @click="logout()">
          {{$t("logout")}}
          
        </div>
        
        <div class="dropdown-divider"></div>

        <div class="dropdown-item clickableValue" v-if="isLoggedIn" @click="toAbout()">
          {{$t("about")}}
        </div>

      </div>
    </div> 
    </form>
  </header>
	
</template>
<script>
import { mapGetters } from 'vuex'
import keycloak from '../keycloak/index'
const titlePostfix = process.env.VUE_APP_TITLE_POSTFIX || "";

export default {
	computed:{
     ...mapGetters(['isScreenWaitingRoom', 'kkJWT']),
    titlePostfix() { return titlePostfix }, 
    aekName() { return this.$store.state.appState.aekName },
    isLoggedIn() { return keycloak.authenticated },
    fixedTop(){ return this.$store.getters.isHeaderFixedTop }
    
  },
  components:{  },
  methods:{
    async werkeinstellungen(){ 
			const decision = await this.$bvModal.msgBoxConfirm(this.$t('factory.reset.confirm.text'), 
			{
          title: this.$t('restore.factory.settings'),
          size: 'lg',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('reset.to.factory.ok'),
          cancelTitle: this.$t('cancel'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
			if(!decision) return
		  this.$store.dispatch('resetApp') 
    },

    toHomeScreen: function(){
      this.$store.dispatch( 'showDashboard' )
      //this.$store.dispatch('updateDashboardData')
		},
    toPublicKeysScreen: function(){
      this.$store.dispatch( 'showPublicKeys' )
		},
    
    toImpressum: function(){
      this.$store.dispatch( 'showImpressum' )
		},
    toPrivacyPolicy: function(){
      this.$store.dispatch( 'showPrivacyPolicy' )
		},
    toAbout: function(){
      this.$store.dispatch( 'showAbout' )
		},
    logout: function(){
      keycloak.logout()
    },
    account: function(){
      keycloak.accountManagement()
    }
  }
}
</script>
<style scoped>

.bg-white {
  background-color: white !important;
}
.fixed-top { 
  z-index: 5; 
  padding-right: max(constraint(safe-area-inset-right), 16px);
  padding-right: max(env(safe-area-inset-right), 16px);
  padding-left: max(constraint(safe-area-inset-left), 16px);
  padding-left: max(env(safe-area-inset-left), 16px);
} 
@supports (padding-top: constant(safe-area-inset-top)) {
  body{
    --safe-area-inset-top: constant(safe-area-inset-top);
    padding-top: calc(var(--safe-area-inset-top) + 76px);
  }
  .fixed-top{
    --safe-area-inset-top: constant(safe-area-inset-top);
    padding-top: calc(var(--safe-area-inset-top) + 0px);
  }
}
@supports (padding-top: env(safe-area-inset-top)) {
  body{
    --safe-area-inset-top: env(safe-area-inset-top);
    padding-top: calc(var(--safe-area-inset-top) + 76px);
  }
  .fixed-top{
    --safe-area-inset-top: env(safe-area-inset-top);
    padding-top: calc(var(--safe-area-inset-top) + 0px);
  }
}

</style>