<template>
	<b-jumbotron >
	<p class="lead">{{$t('public.key.not.configured')}}</p>
</b-jumbotron>
</template>

<script>
export default {

}
</script>

<style>

</style>