import { KEY_PERSISTENT_LOCAL_STORAGE } from './index'
import moment from 'moment'
import i18next from 'i18next'
/* Transient State: wird nicht persistiert */
const transientState = {
	state: () => ({
		mobileAppsMode: false,
		restoreFactorySettings: false,
		credentialsSupported: false,
		loading: false,
		loadingSubject: '',
		displayMomentValueOf: moment().valueOf(),
		credentials: [],
		newCredentialNickname: '',
		scrollPosition: 0,
		scrollDirectionUp: true,
	}),
	getters:{
		credentials: state => state.credentials,
		newCredentialNickname: state => state.newCredentialNickname,
		displayMoment: state => moment(state.displayMomentValueOf),
		credentialsSupported: state => state.credentialsSupported,
		isMobileAppMode: state => state.mobileAppsMode,
		isLoading: state => state.loading,
		loadingSubject: state => state.loadingSubject,
		scrollDirectionUp: state => state.scrollDirectionUp,
		scrollPosition: state => state.scrollPosition,

		
		// isHeaderFixedTop: state => state.scrollDirectionUp && state.scrollPosition > 69,
		// isWarteraumPanelSticky: state => (state.scrollPosition && state.scrollDirectionUp) || state.scrollPosition > 69  ,
		isWarteraumPanelSticky: state =>  state.scrollPosition > 69  ,
		//isWarteraumPanelSticky: () => true  
		isHeaderFixedTop: () => true,

	},
	mutations: {
		credentials: (state, payload) => { state.credentials = payload },
		newCredentialNickname: (state, payload) => { state.newCredentialNickname = payload },
		restoreFactorySettings: (state) => { state.restoreFactorySettings = true },
		scrollPosition: (state, payload) => { 
			state.scrollDirectionUp = state.scrollPosition > payload 
			state.scrollPosition = payload
		},
		displayMoment (state, payload = moment()) { state.displayMomentValueOf = payload.valueOf() },
		setupAppRuntimeSettings: (state) => {
			state.mobileAppsMode = window.cordova?true:false
			state.credentialsSupported = 'credentials' in navigator
		},
		setLoading(state, payload) { state.loading = payload?true:false} ,
		setLoadingWithSubject: (state, payload) => { 
			if(!state.loading) state.loading = true
			state.loadingSubject = payload
		},
		loadCompleted: (state) => { state.loading = false },
	},
	actions: {
		test: (ctx) => console.log('transientState - action'),
		newCredentialNickname(ctx, payload ){ 
			ctx.commit('newCredentialNickname', payload)
		}
	}
}
export default transientState