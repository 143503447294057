<template>
	<div v-bind:class="{ 'with-header-offset': withHeaderOffset}" class="my-3">
	<b-container class=" mt-2" fluid="xl">
			<feedback-message />
			<permanent-message />
			
	</b-container>		
	<h4>Public Keys verwalten</h4>
	<PublicKeysConfig v-for="purpose in kkPublicKeyPurposes" :key="purpose.id" :purpose="purpose"/>
	<ModalEditPublicKey />
</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import FeedbackMessage from '../../FeedbackMessage'
import PublicKeysConfig from '@/components/client/publicKeys/PublicKeyConfig'
import ModalEditPublicKey from './ModalEditPublicKey.vue'
import PermanentMessage from '../../PermanentMessage.vue'
export default {
	computed:{
		...mapGetters(['kkPublicKeyPurposes' ]),
		withHeaderOffset() { return this.$store.getters.isHeaderFixedTop }
	},
	components:{ PublicKeysConfig, FeedbackMessage, ModalEditPublicKey, PermanentMessage },
	methods:{
		
	}

}
</script>

<style>

</style>


		