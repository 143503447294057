<template>
	<div class="app-panel container bg-white my-4 py-4">
		<h5>{{this.purpose.purpose}}</h5>
		<div v-if="publicKey" class="my-3">
			<div class="mb-3">
			<secure-key-icon :size="32" class="text-success" />
			</div>
			<p>
			{{$t('last.modified')}}	{{modified}}
			</p>
			<hr/>
			<div class="text-right">
			<b-button class="mx-2" variant="primary" @click="editPublicKey()">{{$t('edit')}}</b-button>
			<b-button @click="deletePublicKey()">Delete Key</b-button>
			</div>
		</div>
		<div v-else class="my-3">
			<not-configured  />
			<div class="text-right">
				<b-button class="mx-2" variant="primary" @click="editPublicKey()">{{$t('setup.public.key')}}</b-button>
			</div>
		</div>
		
		</div>
</template>

<script>
import NotConfigured from './NotConfigured.vue'
import SecureKeyIcon from 'vue-material-design-icons/ShieldKeyOutline.vue'
import moment from 'moment'
export default {
  components: { NotConfigured, SecureKeyIcon },
	props: ['purpose'],
	computed:{
		publicKey() { return this.$store.getters.kkPublicKeys.find( key => key.purpose_id === this.purpose.id) },
		modified() { return moment(this.publicKey.last_modified).format('DD.MM.YYYY HH:mm') }
	},
	
	methods:{
		editPublicKey: function(){
				const editPublicKey = this.publicKey?this.publicKey.public_key:''
				this.$store.commit('editPublicKey', editPublicKey)
				this.$store.commit('editPurposeId', this.purpose.id)
				this.$bvModal.show('modalEditPublicKey')
			
		},
		deletePublicKey: async function(){
			const decision = await this.$bvModal.msgBoxConfirm(this.$t('delete.public.key.confirm.text', { purpose: this.purpose.purpose }), 
			{
				title: this.$t('inquiry.important'),
				size: 'lg',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: this.$t('delete.permanent'),
				cancelTitle: this.$t('cancel'),
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
       })
			if(!decision) return
			//const result = await this.$store.dispatch('killAll')
			this.$store.commit('setLoading', true)
			const resp =	await this.$store.dispatch('deletePublicKey', this.purpose.id)
			if(resp.isRight){
				this.$store.dispatch('loadPublicKeys')
				this.$bvToast.toast(this.$t('public.key.delete.ok.message'), {
					title: this.purpose.purpose,
								autoHideDelay: 5000,
								variant:'success'
				})
			}else{
				this.$bvToast.toast(this.$t('public.key.delete.failed.message'), {
					title: this.$t('public.key.delete.failed.title'),
								autoHideDelay: 5000,
								variant:'danger'
								})
			}
			this.$store.dispatch('loadPublicKeys')
			this.$store.commit('setLoading', false)
			
		}
	}
}
</script>

<style>

</style>