import { render, staticRenderFns } from "./NotConfigured.vue?vue&type=template&id=47bc9e80&"
import script from "./NotConfigured.vue?vue&type=script&lang=js&"
export * from "./NotConfigured.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports