import Vue from 'vue'
import App from './App.vue'
import 'bootstrap'


import * as serviceWorker from './serviceWorker'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueI18Next from '@panter/vue-i18next';
import i18next from './i18n/i18n'

import './custom.scss'
import './app.css'

import store from './store'
import VueLogger from 'vuejs-logger'
import keycloak from './keycloak/index'
import { initOptions } from './keycloak/index'


// Install BootstrapVue
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueLogger)

Vue.use(VueI18Next)
const i18n = new VueI18Next(i18next);

Vue.config.productionTip = false

//keycloak.init({ onLoad: initOptions.onLoad, scope: 'aekPublicKeyManager' }).then((auth) => {
keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
  if (!auth) {
    window.location.reload()
  } else {
    Vue.$log.info("Authenticated");

    const app = new Vue({
      render: h => h(App, { props: { keycloak: keycloak } }),
      store,
      i18n: i18n,
      beforeCreate() {
        this.$store.commit('setupAppRuntimeSettings')
      },
      methods:{
        onDeviceReady:function(){
          console.log("VueApp on deviceready")
          this.$store.commit('setupAppRuntimeSettings')
          if(window.cordova && 'browser' === window.cordova.platformId){
            serviceWorker.register()
          }
        }
      }
    }).$mount('#app')
    //------------------
  }


//Token Refresh
  setInterval(() => {
    keycloak.updateToken(70).then((refreshed) => {
      if (refreshed) {
        Vue.$log.info('Token refreshed' + refreshed);
      } else {
     //   Vue.$log.warn('Token not refreshed, valid for '
     //     + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
      }
    }).catch(() => {
      Vue.$log.error('Failed to refresh token');
    });
    if( keycloak.isTokenExpired() ) keycloak.logout()
  }, 6000)

}).catch(() => {
  Vue.$log.error("Authenticated Failed");
});

window.keycloak = keycloak // nur zur Debug Zwecken



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if(!window.cordova){
	serviceWorker.unregister();
}

document.addEventListener('deviceready', app.onDeviceReady);