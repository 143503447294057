
/*
const exampleLoginBarcode={
	login:'eiv060',
	pwd:'secret',
	btlrbctype:'aeklbc'
}
"{"login":"praxisOne","pwd":"secret","btlrbctype":"aeklbc"}"

*/
export const recognizeLoginBarcode = (barcode) => {
	if( !barcode || barcode.length < 20) return null
	try{
		const json = JSON.parse(barcode)
		if(json && json.btlrbctype && json.login && json.pwd && json.btlrbctype === 'aeklbc'){
			return { serviceLoginName: json.login, servicePassword: json.pwd}
		}
	}catch(err){
		return null
	}
	return null
}
