
import Either from 'data.either'
import backend from '../backend'
import i18next from '../i18n/i18n'
import keycloak from '../keycloak/index'
import { 
	getAuthTokenForRegistration, preformatMakeCredReq, getAuthTokenForLogin, 
	preformatGetAssertReq, publicKeyCredentialToJSON } from '../butler/webauthnHelper'
import { fetchJson, fetchJsonWithBasicAuth, fetchJsonWithJWT, 
	catchResponse401ResetAuthToken, catchResponse403 } from '../commons/utils'

const modulState = {

	state: () => ({
		loginName: '',
		password: '',
		jwt: null,
		assertOptions: null,
		inputsDisabled: false,
		publicKeyPurposes: [],
		publicKeys: [],
		editPurposeId: '',
		editPublicKey: '',
		tr_4_1: []
	}),
	getters:{
		editPurposeId: state => state.editPurposeId,
		editPublicKey: state => state.editPublicKey,
		kkPublicKeyPurposes: state => state.publicKeyPurposes,
		kkPublicKeys: state => state.publicKeys,
		tr_4_1: state => state.tr_4_1
	},
	mutations: {
		editPublicKey(state, payload) { state.editPublicKey = payload },
		editPurposeId(state, payload) { state.editPurposeId = payload },
		kkPublicKeyPurposes(state, payload) { state.publicKeyPurposes = payload },
		kkPublicKeys(state, payload) { state.publicKeys = payload },
		tr_4_1(state, payload) { state.tr_4_1 = payload?payload:[] }
  },
	actions: {
		test: (ctx) => console.log('kkState - action'),
		loadPublicKeyPurposes: async (ctx) => {
			const jwt = keycloak.token
			ctx.commit('setLoading', true)
			const responseDataLoading = await loadPurposes(ctx, jwt)
			if( responseDataLoading.isRight ){
					const puroses = responseDataLoading.value
					ctx.commit('kkPublicKeyPurposes', puroses)
					console.log('Loaded purposes:', puroses)
			}
			ctx.commit('setLoading', false)
			return responseDataLoading
		},

		deletePublicKey: async (ctx, purposeId) => {
			const url = `${backend.URL_GET_POST_PUBLIC_KEYS}/${purposeId}`
			const jwt = keycloak.token
			const result = await fetchJsonWithJWT(jwt, url, 'DELETE')
			catchResponse401ResetAuthToken(ctx, result)
			catchResponse403(ctx, result)
			// nur status 200 ist interessant
			if(result.isRight){
				if(result.value.response.status === 200){
					// service_db_clock_timestamp ---> lastUpdateTimestamp
					const publicKeys = result.value.json
					return Either.Right(publicKeys)
				}
			}
			return result
		},

		loadPublicKeys: async (ctx) => {
			const jwt = keycloak.token
			ctx.commit('setLoading', true)
			const responsePublicKeyLoading = await loadPublicKeys(ctx, jwt)
			if( responsePublicKeyLoading.isRight ){
					const publicKeys = responsePublicKeyLoading.value
					ctx.commit('kkPublicKeys', publicKeys)
					console.log('Loaded publicKeys:', publicKeys)
			}
			ctx.commit('setLoading', false)
			return responsePublicKeyLoading
		},

		submitPublicKey: async (ctx) => {
			const url = `${backend.URL_GET_POST_PUBLIC_KEYS}`
			const jwt = keycloak.token
			const result = await fetchJsonWithJWT(jwt, url, 'POST', 
				{ purposeId: ctx.getters.editPurposeId, public_key: ctx.getters.editPublicKey.trim()	})
			catchResponse401ResetAuthToken(ctx, result)
			catchResponse403(ctx, result)
			// nur status 200 ist interessant
			if(result.isRight){
				if(result.value.response.status === 200){
					// service_db_clock_timestamp ---> lastUpdateTimestamp
					const publicKeys = result.value.json
					return Either.Right(publicKeys)
				}
			}
			return result
		},
		response403: async ctx => {
			ctx.dispatch('setFeedbackMessage', {message: i18next.t('feedback.message.access.denied'), variant:'warning' })
		},

		/** Technische Richtlinie 4.1 Ärztekammerdaten */
		loadAEKReferenceData: async (ctx) => {
			const response = await loadAEK_TR_4_1()
			if(response.isRight){
				const tr_4_1 = response.value
				//ctx.dispatch('setFeedbackMessage', { message:i18next.t('anmeldung.erfolgreich'), variant:'success', ttl:1200 })
				ctx.commit('tr_4_1', tr_4_1)
			}else{
				const left = response.value
				if(left.error && left.error.stack && left.error.stack === 'TypeError: Failed to fetch'){
					ctx.commit('feedbackMessage', { message:i18next.t('common.network.error'), variant:'danger' })
				}
			}
			return response
		},
		checkRequiredUserAttributes: async ctx => {
			if(!keycloak.tokenParsed.kammerCode)
			ctx.dispatch('setPermanentMessage', {message: i18next.t('feedback.message.missing.kammercode.attribute'), variant:'danger'})
		},
		updateAekName: (ctx) => {
			let aekName = i18next.t('aek.name.default')
			
			//console.log(payloadOfJWT)
			const kid = keycloak.tokenParsed.kammerCode?keycloak.tokenParsed.kammerCode:'000'
			// TODO !!!!

			const aek = ctx.getters.tr_4_1.find( k => k.code === kid )
			if(aek){
				aekName = `${aek.kategorie} - ${aek.aerztekammer} (${kid})`
			}
			
			ctx.commit('aekName', aekName)
			
		},
	
	}
}

/**
 * loads all purposes of public key using
 * @param ctx - context of vuex 
 * @param authorizationToken - authorizationToken
 */
const loadPurposes = async (ctx, authorizationToken) => {
	const limit = 0
	const url = `${backend.URL_GET_PUBLIC_KEY_PURPOSES}?limit=${limit}`
		
	const result = await fetchJsonWithJWT(authorizationToken, url, 'GET')
	catchResponse401ResetAuthToken(ctx, result)
	// nur status 200 ist interessant
	if(result.isRight){
		if(result.value.response.status === 200){
			// service_db_clock_timestamp ---> lastUpdateTimestamp
			const purposesRows = result.value.json
			const purposes = purposesRows.map( row => { 
				const {id, purpose } = row
				return { id, purpose }
			})
			return Either.Right(purposes)
		}
	}
	return result
}
/**
 * loads all public keys stored on backend
 * @param ctx - context of vuex 
 * @param authorizationToken - authorizationToken
 */
const loadPublicKeys = async (ctx, authorizationToken) => {
	const url = `${backend.URL_GET_POST_PUBLIC_KEYS}?limit=0`
		
	const result = await fetchJsonWithJWT(authorizationToken, url, 'GET')
	//const result = await fetchJson(url, 'GET')
	catchResponse401ResetAuthToken(ctx, result)
	catchResponse403(ctx, result)
	// nur status 200 ist interessant
	if(result.isRight){
		if(result.value.response.status === 200){
			// service_db_clock_timestamp ---> lastUpdateTimestamp
			const publicKeys = result.value.json
			return Either.Right(publicKeys)
		}
	}
	return result
}

const loadAEK_TR_4_1 = async () => {
	
	const url = `${backend.URL_GET_TR_4_1}?limit=0`
	//console.debug(url)
	
	const result = await fetchJson(url, 'GET')
	// nur status 200 ist interessant
	if(result.isRight){
		if(result.value.response.status === 200){
			// service_db_clock_timestamp ---> lastUpdateTimestamp
			const credentials = result.value.json
			return Either.Right(credentials)
		}
	}
	return result
}

export default modulState
