<template>
	<b-alert :variant="getPermanentVariant" fade :show="showMessage" >{{getPermanentMessage}}</b-alert>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
	computed:{
		...mapGetters(['getPermanentMessage', 'getPermanentVariant']),
		showMessage(){ return this.getPermanentMessage?true:false}
	},

}
		
</script>

<style>

</style>