<template>
<div >
	<b-modal 
		header-bg-variant="info" footer-bg-variant="light" body-bg-variant="light" 
		:no-close-on-backdrop="true"
		:no-close-on-esc="true" footer-class="d-none"
		header-text-variant="light"
		ok-variant="secondary" :ok-title="$t('close')" size="lg" id="modalEditPublicKey" 
		:title="$t('edit.public.key.title')" ok-only centered>
    <b-container fluid class=" text-center"  >
			<b-row class="my-2 justify-content-md-center"  align-h="center" align-v="start">
				<h5>{{purpose?purpose.purpose:""}}</h5>
				<b-col sm="12"  class="py-2" >
					<b-card>
				<div class="small text-muted mb-3">
					{{$t('modal.public.key.header')}}
				</div>
					<b-form @submit.stop.prevent>
						<b-form-textarea
    				  ref="editVaueInput"
      				v-model="editValue"
      				:placeholder=	"$t('modal.public.key.placeholder')" 
							@keyup="editOnKeyUp($event)" aria-describedby="input-help-block"
      				rows="8" max-rows="16">
						</b-form-textarea>
      			<b-form-text id="input-help-block">{{$t('modal.public.key.footer')}}</b-form-text>
				</b-form>
					</b-card>
				</b-col>
			</b-row>
			<b-row class="my-2">
				<b-col sm="12" class="text-right">
					<b-button class="mx-2" :disabled="!isApplyEnabled" @click="applyPublicKey()" variant="primary">{{$t('save.public.key')}} </b-button>
					<b-button @click="closeModal()" variant="secondary">{{$t('close')}} </b-button>
				</b-col>
			</b-row>
  </b-container>


  </b-modal>
</div>
</template>
</div>
<script>
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'

export default {
	data: function () {
		return {
			}
	},
	computed:{
		...mapGetters([ 'isMobileAppMode', 'editPublicKey', 'editPurposeId' ]),
		purpose() { return this.$store.getters.kkPublicKeyPurposes.find( key => key.id === this.editPurposeId) },
		isApplyEnabled() { return this.editPublicKey.trim().length >= 12 },
		editValue: {
			get () {
				return this.$store.getters.editPublicKey
			},
			set (value) {
				this.$store.commit('editPublicKey', value)
			}
		},
		
  },
	components: { },
	methods:{
		editOnKeyUp(event){
			if( event.keyCode === 27 ) this.closeModal()
			
		},
		async applyPublicKey(){
			this.$store.commit('setLoading', true)
			const resp =	await this.$store.dispatch('submitPublicKey')
			if(resp.isRight){
				this.$store.dispatch('loadPublicKeys')
				this.$bvToast.toast(this.$t('public.key.save.ok.message'), {
					title: this.$t('public.key.save.ok.title'),
								autoHideDelay: 5000,
								variant:'success'
				})
			}else{
				this.$bvToast.toast(this.$t('public.key.save.failed.message'), {
					title: this.$t('public.key.save.failed.title'),
								autoHideDelay: 5000,
								variant:'danger'
								})
			}
			this.$store.commit('setLoading', true)
			this.closeModal()
		},
		closeModal(){ 
			this.$store.dispatch('loadPublicKeys')
			this.$bvModal.hide('modalEditPublicKey') }
		}

}
</script>

<style>
.card-body{
	position: relative;
}
</style>