<template>
  <div id="app">
    <AppHeader class="border-bottom"/>
    <Loader v-if='isLoading' />
    
    <Dashboard v-if='isScreenDashboard'/>
    <PublicKeysScreen v-else-if='isScreenPublicKeys'/>
    <About v-else-if='isScreenAbout'/>
    <Impressum v-else-if='isScreenImressum'/>
    <PrivacyPolicy v-else-if="isScreenPrivacyPolicy" />
      
  </div>
</template>

<script>
import Loader from '@/components/Loader'
import { request, requestJWT, parseJwt } from '@/commons/utils'
import AppHeader from '@/components/AppHeader'
import Dashboard from '@/components/client/Dashboard'
import PublicKeysScreen from './components/client/publicKeys/PublicKeysScreen'
import About from '@/components/About'



import LoginScreen from '@/components/screens/Login'
//import Backend from '@/backend'

import { mapGetters } from 'vuex'
import _throttle from 'lodash/throttle'


export default {
  name: 'App',
  computed:{
     ...mapGetters(['currentStep', 'isLoading', 'kkJWT', 'isScreenDashboard', 
     'isScreenPublicKeys','isScreenAbout', 'isScreenImressum', 'isScreenPrivacyPolicy']),
     forceLogin() { return this.kkJWT?false:true }
  },
  components: {
   Loader, AppHeader, Dashboard, PublicKeysScreen,
   About
  },
  async mounted() {
    await this.$store.dispatch('setPermanentMessage', '')
    await this.$store.dispatch('loadAEKReferenceData')
    this.$store.dispatch('updateAekName')
    await this.$store.dispatch('loadPublicKeyPurposes')
		await this.$store.dispatch('loadPublicKeys')
    this.$store.dispatch('checkRequiredUserAttributes')
    
     // DEBUG expose als butlerKC
    window.butlerKC = this
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods:{
    
    handleScroll(event){
      //this.$store.commit('scrollPosition', window.scrollY )
      throttledCommit(this.$store)
    }
  },
}
const throttledCommit = _throttle((store) => { store.commit('scrollPosition', window.scrollY )}, 300)
</script>

<style>
#app {
  text-align: center;
  color: #2c3e50;
  /* margin-top: 3rem; */
}
</style>
