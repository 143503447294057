import Keycloak from 'keycloak-js';

export const initOptions = {
  url: 'https://accounts.laekh.de/auth', realm: 'aek-services', 
  clientId: 'butler-kammer-app', onLoad: 'login-required'
  // ,scope: 'offline_access'
}

const keycloak = new Keycloak(initOptions);

export default keycloak