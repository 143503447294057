//const URL_BACKEND = 'https://butler-test.laekh.de/backend/v1'
//const URL_BACKEND = 'http://localhost:8082'

const URL_BACKEND = process.env.VUE_APP_BACKEND_URL || 'https://bbt.baek.systems'
console.log('backend url: %s', URL_BACKEND);

export const URL_GET_TR_4_1 = `${URL_BACKEND}/referenzdaten/tr_4_1`

export const URL_GET_PUBLIC_KEY_PURPOSES = `${URL_BACKEND}/referenzdaten/public_key_purpose`
export const URL_GET_POST_PUBLIC_KEYS = `${URL_BACKEND}/aek/base/public_key`

export const UNEXPECTED_STATUS ='unexpected_status'


export default { URL_BACKEND, URL_GET_PUBLIC_KEY_PURPOSES, URL_GET_POST_PUBLIC_KEYS, URL_GET_TR_4_1 }
