<template>
	<div class="with-header-offset">
		<h5>{{$t('about')}}</h5>
		<div class="app-panel container bg-white my-4 text-muted py-4">
			<h4> <img  src="@/resources/storelogo.png" /> {{$t('app.name')}} </h4>
			<div class="my-4">
			{{$t('version.info')}} {{version}}<br>
			
			</div>
			
			<div>
				{{$t('about.author')}}
			</div>	
			<div>
				{{$t('about.credits')}}
			</div>	
			<div class="my-4">
				{{$t('about.entwicklung.fuer')}}
			</div>	
			<div class="text-left">
				<p></p>
			</div>
						
			</div>
	</div>
</template>

<script>
export default {
	computed:{
		version(){ return this.$store.getters.version }
	}
	
}
</script>

<style scoped>
.with-header-offset{
	padding-top: 6rem;
}
</style>