<template>
	<div v-bind:class="{ 'with-header-offset': withHeaderOffset}">
		
		<b-container class=" mt-2" fluid="xl">
			<FeedbackMessage />
			<permanent-message />
			
		</b-container>
			<h4>{{$t('dashboard')}}</h4>

			<div class="app-panel container bg-white my-4 py-4">
				<PublicKeysControl />
				<hr/>
				<p class="mt-4">Hier ist noch Platz für weitere Funktionen .....</p>
			</div>
	</div>	
</template>

<script>
import FeedbackMessage from '../FeedbackMessage'
import moment from 'moment'
import Either from 'data.either/lib/either'
import PublicKeysControl from './publicKeys/DashboardControl'
import momentDurationFormatSetup from "moment-duration-format"
import { mapGetters, mapState } from 'vuex'
import PermanentMessage from '../PermanentMessage.vue'
export default {
	components : { FeedbackMessage, PublicKeysControl, PermanentMessage },
	computed:{
		withHeaderOffset() { return this.$store.getters.isHeaderFixedTop }
	},
	created() {
		momentDurationFormatSetup(moment)
		this.handleIntervalDisplayMoment = setInterval(() => { this.$store.commit('displayMoment', moment()) }, 1000)
		//this.handleIntervalFetchServerData = setInterval(() => { this.$store.dispatch('updateWaiters') }, 60 * 1000)
	},
	mounted(){
		this.$store.dispatch('loadPublicKeyPurposes')
		this.$store.dispatch('loadPublicKeys')
		//this.$store.dispatch('updateWaiters')
	},

	beforeDestroy() {
   	clearInterval(this.handleIntervalDisplayMoment)
   	//clearInterval(this.handleIntervalFetchServerData)
  }

}
</script>
<style>
.with-header-offset{
	padding-top: 69px;
}
</style>